import { writable } from 'svelte/store';
import { browser } from '$app/env';

/* 
appStore = { 
        $appStores.user = user{} // Supabase Auth User Details
        $appStores.appUser = user{} // 'users' Table User Data
        $appStores.environment = string of environment (dev, staging, prod)
        $appStores.platform = string of platform (web, mobile, tablet)
        $appStores.language = string of language (en, fr, es, etc)
        $appStores.userTimeoutAt = DateTime for session timeout
        $appStores.pageStates = { 
                trackingNumberFilters: { 

                }
                packOrder: { 
                        packingStationKey: string of packing station name (correlates to packing_station_config.js)
                }
        }
        $appStores.componentStates = { 
                dropdownButton: { 
                        openInstance: string of dropdown instance
                }

        }
}
*/

const storedAppStores = JSON.parse(browser && localStorage.getItem('appStores')) || {
	user: undefined,
	language: 'en',
	pageStates: {},
};

export const appStores = writable(browser && storedAppStores);
appStores.subscribe((val) => {
	browser && (localStorage.appStores = JSON.stringify(val));
});
